<template>
  <div class="auth-wrapper">
    <section class="auth-card">
      <div class="col-1">
        <img
          class="img-1"
          src="@/assets/images/login/login.png"
          alt=""
          width="30%"
          height="30%"
        />
        <h2>Create Account</h2>
        <p>Get start with create an account</p>
        <form action="" @submit.prevent="ConfirmRegister">
          <Input
            label="Name"
            v-model="name"
            :validation="['required', 'minLengthRules']"
            @isValid="nameIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="text"
            icon="bx bxs-user bx-tada"
          ></Input>
          <Input
            label="Email"
            v-model="email"
            :validation="['required', 'emailRules']"
            @isValid="EmailIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="email"
            icon="bx bxs-envelope bx-tada"
          ></Input>

          <Input
            label="Password"
            v-model="password"
            :validation="['required']"
            @isValid="PasswordIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="password"
            icon="bx bxs-lock bx-tada"
          ></Input>
          <Input
            label="Password Confirm"
            v-model="passwordConfirm"
            :validation="['required']"
            @isValid="PasswordConfirmIsValid"
            :checkValidationFlag="checkValidationFlag"
            type="password"
            icon="bx bxs-lock bx-tada"
          ></Input>
          <p
            :style="passwordsMatch ? 'opacity:0' : 'opacity:1'"
            class="passwords-not-match"
          >
            <i class="bx bxs-error-circle bx-tada"></i> Passwords do Not match.
            Try again
          </p>
          <Input
            label="Referral code"
            v-model="referralCode"
            type="text"
            icon="bx bxs-user-detail bx-tada"
            class="mt-10"
          ></Input>
          <VueRecaptcha
            class="recaptcha"
            @verify="successCaptcha"
            @expired="expireCaptcha"
            :sitekey="sitekey"
          />
          <p
            style="color: red; margin: 0; font-size: 13px"
            :style="`${loginClicked && !captcha ? 'opacity:1' : 'opacity:0'}`"
          >
            *Recaptcha Required
          </p>
          <button type="submit" class="auth-btn login-btn lt-hover">
            <Loading v-if="loading"></Loading>
            <div style="display: flex; align-items: center" v-else>
              Sign Up
              <i class="bx bxs-user-plus bx-burst"></i>
            </div>
          </button>
          <Or class="or-line"></Or>
          <GoogleLogin
            name="Continue with Google"
            :referralCode="referralCode"
          ></GoogleLogin>
        </form>
        <div class="other-auth">
          <p>Already have an account?</p>
          <routerLink :to="{ name: 'login' }"> Sign In </routerLink>
        </div>
        <div class="socials">
          <i class="bx bxl-telegram"></i>
          <i class="bx bxl-twitter"></i>
          <i class="bx bxl-instagram-alt"></i>
        </div>
      </div>
      <div class="col-2">
        <img
          class="img-2"
          src="@/assets/images/login/reg-bg.png"
          alt=""
          width="83%"
        />
      </div>
    </section>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { captcha } from "@/mixins/captcha.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      name: "",
      nameValidation: "",
      email: "",
      emailValidation: null,
      password: null,
      passwordValidation: null,
      passwordConfirm: null,
      passwordConfirmValidation: null,
      passwordsMatch: true,
      checkValidationFlag: false,
      referralCode: null,
      loading: false,
      loginClicked: false,
    };
  },
  mixins: [captcha],
  components: { VueRecaptcha },
  computed: {
    ...mapGetters(["returnRoute"]),
  },
  watch: {
    passwordConfirm() {
      this.passwordsIsMatch();
    },
    password() {
      if (this.passwordConfirm) this.passwordsIsMatch();
    },
  },
  created() {
    if (this.$route.query.referral)
      this.referralCode = this.$route.query.referral;
  },
  methods: {
    ...mapActions(["register", "clearReturnRoute"]),
    nameIsValid(v) {
      this.nameValidation = v;
    },
    EmailIsValid(v) {
      this.emailValidation = v;
    },
    PasswordIsValid(v) {
      this.passwordValidation = v;
    },
    PasswordConfirmIsValid(v) {
      this.passwordConfirmValidation = v;
    },

    ConfirmRegister() {
      if (this.formIsValid()) {
        //check for form is valid
        this.loginClicked = true;
        if (this.captcha) {
          if (this.passwordsMatch) {
            const registerData = {
              name: this.name,
              email: this.email,
              password: this.password,
              password_confirmation: this.passwordConfirm,
              referral: this.referralCode,
              "g-recaptcha": this.captcha,
            };
            this.loading = true;
            this.register(registerData)
              .then(() => {
                if (this.returnRoute) {
                  this.$router.push({
                    name: this.returnRoute.name.substring(12),
                    params: { id: this.returnRoute.params.id },
                  });
                  this.clearReturnRoute();
                } else {
                  this.$router.push({ name: "dashboard" });
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      } else {
        //if form is not valid
        this.checkValidationFlag = true;
      }
    },
    formIsValid() {
      return (
        this.nameValidation &&
        this.emailValidation &&
        this.passwordValidation &&
        this.passwordConfirmValidation
      );
    },
    passwordsIsMatch() {
      if (this.password === this.passwordConfirm) {
        //check passwords match
        this.passwordsMatch = true;
      } else {
        //if passwords not match
        this.passwordsMatch = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_reg&login.scss";
.auth-wrapper {
  height: 132vh;
  .auth-btn {
    margin-top: 0px !important;
  }
  .col-2 {
    background-position: right;
    .img-2 {
      bottom: 0;
      right: -20px;
    }
  }
}
</style>
