export const captcha = {
  data() {
      return {
          captcha: ""
      };
  },

  computed: {
      sitekey() {
          return process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY;
      }
  },

  methods: {
      successCaptcha(token) {
          this.captcha = token;
      },

      expireCaptcha() {
          this.captcha = "";
      }
  }
};